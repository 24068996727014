<template>
  <div v-if="partnerData"
    class="relative group cursor-pointer lg:rounded-lg lg:h-post-img lg:max-h-post-img"
    :class="[getHeightOfScreenWidth(0.75),]"
    @click="$emit('partner-clicked', partnerData.slug)"
    :style="[{
      background: 'url(' + partnerData.image + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }]"
  >
    <div class="flex flex-col justify-end h-full lg:rounded-lg">
      <div class="p-3">
        <div class="flex flex-col p-3 bg-white w-full border border-white opacity-95 rounded-lg">
          <div class="text-black font-semibold leading-tight"
               :class="[{'text-base':small, 'text-lg':!small}]"
          >
            {{ partnerData.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon.vue";
import router from "@/router";
import Button from "@/utils/crud/components/Button.vue";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import {getWidth, getHeightOfScreenWidth, excerpt} from "@/common/helpers/utils";
import PictureLoader from "@/views/PictureLoader.vue";

export default {
  name: "PartnerCard",
  components: {PictureLoader, Button, BaseIcon},
  data() {
    return {
      isMobile: checkIsMobileDevice(),
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      getWidth: getWidth,
      narrow: Boolean,
      small: Boolean,
    }
  },
  props: {
    partnerData: Object,
    index: Number,
  },
  methods: {
    excerpt
  },
}
</script>